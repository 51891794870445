import styled from "@emotion/styled";
import { TOP_LAYER } from "constants/z-index";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";
import { FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
export const CARDS_MARGIN = "24px";
const HEADER_CONTENT_HORIZONTAL_PADDING = `${Constants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;
const FULL_SCREEN_LEFT_PADDING = "24px";
const ARROW_WIDTH = "23px";
const ARROW_MARGIN = "16px";
const DEFAULT_CARD_BORDER_RADIUS = "8px";
export const StyledSlidingCardGridBaseComponent = styled.div`
	max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
	margin: auto;
	display: flex;
	flex-direction: column;

	&.with-title {
		.carousel .slick-slider .slick-list .slick-track {
			padding: 0;
		}
	}

	&.full-carousel-width {
		max-width: ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
		padding-left: calc(
						max(min(100vw, ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) - ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px, 0px) / 2 +
						${HEADER_CONTENT_HORIZONTAL_PADDING}
		);
	}

	&.full-screen {
		max-width: ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
		padding-right: 0;
		padding-left: 24px;

		.carousel-arrow-wrapper {
			&.next {
				left: calc(50% - ${FULL_SCREEN_LEFT_PADDING});
			}

			&.prev {
				left: calc(50% - ${FULL_SCREEN_LEFT_PADDING} - ${ARROW_WIDTH});
			}
		}
	}

	.carousel {
		.slick-slider {
			.slick-list {
				.slick-track {
					display: flex;
					padding-bottom: 80px;
					padding-top: 0;

					.slick-slide {
						height: auto;

						> div:first-child {
							height: 100%;
						}
					}

					.carousel-item {
						height: 100%;

						.card-grid-link {
							.grid-paragraph-with-image {
								cursor: grab;
								border-radius: ${({ cardBorderRadius })=>cardBorderRadius || DEFAULT_CARD_BORDER_RADIUS};
								margin-right: ${CARDS_MARGIN};
								min-height: 100%;
								width: 100%;

								.grid-paragraph-wrapper {
									display: flex;

									.full-paragraph {
										.button-wrapper {
											min-height: 56px;
											margin-top: 0;

											.button {
												display: flex;
												align-items: flex-end;
											}
										}
									}
								}

								.grid-image-wrapper {
									img {
										border-bottom-left-radius: 8px;
										border-bottom-right-radius: 8px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	&.disable-hover-effect {
		cursor: initial;

		.carousel {
			.slick-slider {
				.slick-list {
					.slick-track {
						.slick-slide:last-child {
							.grid-paragraph-with-image {
								margin-right: 0;
							}
						}

						.carousel-item {
							.card-grid-link {
								.grid-paragraph-with-image {
									cursor: initial;
								}
							}
						}
					}
				}
			}
		}
	}

	.carousel .carousel-arrow-wrapper {
		top: auto;
		position: absolute;
		bottom: 0;
		z-index: ${TOP_LAYER};
		height: fit-content;
		width: fit-content;

		&.next {
			left: 50%;
			margin-left: ${ARROW_MARGIN};

			&::before {
				content: none;
			}
		}

		&.prev {
			left: calc(50% - ${ARROW_WIDTH});
			margin-left: -${ARROW_MARGIN};

			&::before {
				content: none;
			}
		}
	}
`;
